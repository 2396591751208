import React from "react";
import whatsapp from "@/assets/images/icons/whatsapp.svg";

const whatsappIcon: React.FC = () => {
  return (
    <div className="flex items-center   rounded-3xl ">
      <img src={whatsapp} alt="Mobile OTP Icon" className="h-6 w-6" />
    </div>
  );
};

export default whatsappIcon;
