import { t } from 'i18next'
import SuccessIcon from '@/components/icons/SuccessIcon'
import { useTicketsQuery } from '@/queries/user.ticket'
import { Button } from '@/components/ui/button'
import browserLanguage from '@/hooks/browserLanguage'
import { ShowModalProps } from '@/types/showModal.type'

const SuccessHelpRequest = ({ showModal, closeModal }: ShowModalProps) => {
    const { data: Tickets } = useTicketsQuery()

    const TicketsID =
    Tickets?.length > 0
        ? Tickets.reduce(
              (max: number, ticket: { id: number }) =>
                  ticket.id > max ? ticket.id : max,
              Tickets[0].id
          )
        : null
    return (
        <div dir={t('common.dir') || 'rtl'}>
            {showModal && (
                <div className="fixed inset-0 flex justify-center items-center bg-black bg-opacity-50 z-50">
                    <div className="relative bg-white w-[80%] md:w-[30%]    mx-auto md:p-4 rounded-[16px] shadow-md">
                        <div className="flex flex-col items-center mt-8 text-center">
                            <SuccessIcon />
                            <div className="w-full  p-4  rounded-md mb-4">
                                <div className=" items-center w-full md:text-[28px] text-[20px] font-semibold  mb-2">
                                    {t('successHelpRequest.title')}
                                </div>
                                <div className="  items-center text-[14px] font-normal w-full">
                                    {t('successHelpRequest.message')}
                                </div>
                                <div className="md:flex text-[14px] mt-2 justify-center ">
                                    <h1 className={`${browserLanguage ? "pl-1":"pr-1"}`}>
                                        {t('successHelpRequest.ticketID')}: #
                                        {TicketsID},
                                    </h1>
                                    <h1>
                                      {t('successHelpRequest.description')}
                                    </h1>
                                </div>
                            </div>
                            <div className="w-full    p-4 rounded-md ">
                                <Button
                                    variant={'default'}
                                    onClick={closeModal}
                                    
                                    className="w-full h-14 text-[18px]  font-semibold   "
                                >
                                    {t('successHelpRequest.Button')}
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    )
}

export default SuccessHelpRequest
