import React from "react";
import { ReactSVG } from 'react-svg';
import Arrow_right from "@/assets/images/arrows/arrow-right.svg";
import browserLanguage from "@/hooks/browserLanguage";
import { ColorProps } from "@/types/colorIcon.type";



const Arrowright: React.FC<ColorProps> = ({ className = "", strokeColor = "white" }) => {
  const defaultClass = browserLanguage ? "mr-2 scale-x-[-1] " : "ml-2 ";
  
  return (
    <div className={`${defaultClass} ${className}`}>
      <ReactSVG
        src={Arrow_right}
        beforeInjection={(svg) => {
          svg.querySelector('path')?.setAttribute('stroke', strokeColor); 
        }}
      />
    </div>
  );
};

export default Arrowright;
