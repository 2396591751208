import { Helmet } from 'react-helmet-async'
import { t } from 'i18next'
import ContactInfo from '@/components/Help/ContactInfo'
import QuestionItem from '@/components/Help/QuestionItem'
import LicenseInfo from '@/components/Help/LicenseInfo'
import { questions } from '@/components/Help/Questions'
import AboutUs from '@/components/Help/AboutUs'
import browserLanguage from '@/hooks/browserLanguage'
import TechnicalRequest from '@/components/Help/TechnicalRequest'
import { useTicketsQuery } from '@/queries/user.ticket'
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

const Help = () => {
    const { data: Tickets, isLoading } = useTicketsQuery()

    return (
        <div className="flex justify-center items-center ">
            <Helmet>
                <title>
                    {browserLanguage ? 'ايجاري | مساعدة' : 'Ejari | Help'}
                </title>
            </Helmet>
            <div className="h-full w-full md:p-10 p-4   ">
                {isLoading ? (
                    <>
                        <Skeleton
                            style={{
                                height: 150,
                                marginBottom: 10,
                                borderRadius: 15,
                            }}
                        />
                    </>
                ) : Tickets && Tickets.length === 1 ? (
                    <TechnicalRequest />
                ) : null}
                <ContactInfo />

                <div className="md:mb-4 bg-[#FBFBFB] rounded-[24px] w-full h-full md:p-8 p-4">
                    <h3 className="text-[28px] ml-2 text-gray-900 font-semibold mb-2">
                        {t('help.qandA.header')}
                    </h3>
                    {questions.map((item) => (
                        <QuestionItem
                            key={item.id}
                            {...item}
                            isArabic={browserLanguage}
                        />
                    ))}
                </div>
                <div className="md:flex justify-between p-8 items-center">
                    <AboutUs />
                    <LicenseInfo />
                </div>
            </div>
        </div>
    )
}

export default Help
