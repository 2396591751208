import { useState } from 'react'
import Arrowright from '../icons/Arrowright'
import browserLanguage from '@/hooks/browserLanguage'
import { Button } from '../ui/button'
import { QuestionItemProps } from '@/types/questionHelp.type'

const QuestionItem = ({
    question,
    question_ar,
    answer,
    answer_ar,
    isArabic,
}: QuestionItemProps) => {
    const [isOpen, setIsOpen] = useState(false)

    const toggleQuestion = () => {
        setIsOpen(!isOpen)
    }

    return (
        <div className="mt-2 px-2 py-4 w-full ">
            <button
                onClick={toggleQuestion}
                className={`flex justify-between w-full  text-left   text-sm font-semibold text-gray-800 ${
                    isOpen ? '' : 'border-b '
                }`}
            >
                <h1
                    className={`md:text-[20px] text-[16px] pb-8 text-[#27262C] font-semibold   ${
                        isArabic ? 'text-right' : 'text-left'
                    } `}
                >
                    {isArabic ? question_ar : question}
                </h1>
                <Button className=" bg-[#F7F7F8] w-7 h-8 rounded-full">
                    {browserLanguage ? (
                        isOpen ? (
                            <Arrowright
                                className="rotate-90 md:ml-2 ml-3  "
                                strokeColor="#3B3A43"
                            />
                        ) : (
                            <Arrowright
                                className=" -rotate-90 md:ml-2 ml-3 "
                                strokeColor="#3B3A43"
                            />
                        )
                    ) : isOpen ? (
                        <Arrowright
                            className="-rotate-90 md:mr-2 mr-2 "
                            strokeColor="#3B3A43"
                        />
                    ) : (
                        <Arrowright
                            className=" rotate-90 md:ml-2 mr-2 "
                            strokeColor="#3B3A43"
                        />
                    )}
                </Button>
            </button>
            {isOpen && (
                <div className=" pb-4 rounded-b-md border-b ">
                    <p
                        className={`text-[18px] text-[#27262C] w-full  ${
                            isArabic ? 'text-right' : 'text-left'
                        } `}
                    >
                        {isArabic ? answer_ar : answer}
                    </p>
                </div>
            )}
        </div>
    )
}

export default QuestionItem
