// router.tsx
import  { lazy } from 'react';
import { createBrowserRouter } from 'react-router-dom';
import {
    App,
    Layout,
    MyRentings,
    Onboarding,
    PartnerNetwork,
    Profile,
    Protected,
    SignIn,
    Unauthorized,
} from './file.ts';
import Help from './pages/dashboard/help.tsx';
import TicketsPage from './pages/dashboard/TicketsPage.tsx';
import TicketDetails from './components/Help/Tickets/TicketDetails.tsx';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';

export const Error = lazy(() => import('./error.tsx'));

const router = createBrowserRouter([
    {
        path: '/',
        element: (
            <Unauthorized>
                <App />
            </Unauthorized>
        ),
    },
    {
        path: '/',
        element: (
            <Protected>
                <Layout />
            </Protected>
        ),
        errorElement: <Error />,
        children: [
            { path: 'dashboard', element: <App /> },
            { path: 'help', element: <Help /> },
            { path: 'profile', element: <Profile /> },
            { path: 'myRentals', element: <MyRentings /> },
            { path: 'PartnerNetwork', element: <PartnerNetwork /> },
            { path: 'tickets', element: <TicketsPage /> },
            { path: '/tickets/:ticketNumber', element: <TicketDetails /> },
        ],
    },
    {
        path: '/auth/',
        children: [
            {
                path: 'signIn',
                element: (
                    <GoogleReCaptchaProvider
                        reCaptchaKey={import.meta.env.VITE_RECAPTCHA_KEY}
                    >
                        <SignIn />
                    </GoogleReCaptchaProvider>
                ),
            },
            {
                path: 'Information',
                element: (
                    <GoogleReCaptchaProvider
                        reCaptchaKey={import.meta.env.VITE_RECAPTCHA_KEY}
                    >
                        <Onboarding />
                    </GoogleReCaptchaProvider>
                ),
            },
        ],
    },
    { path: '*', element: <Error /> },
]);

export default router;
