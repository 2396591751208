import React from "react";
import documentupload from "@/assets/images/icons/document-upload.svg"
 const DocumentuploadIcon:React.FC = () => {
  return (
    <div className="flex items-center   rounded-3xl ">
    <img src={documentupload} alt="Mobile OTP Icon" className=" w-6" />
  </div>
  )
}

export default DocumentuploadIcon;