import { t } from 'i18next'

const AboutUs = () => {
  return (
    <div className=" p-2 text-sm">
    <p><strong className=' md:text-[28px] text-[20px]'>{t("help.aboutUs.title")}</strong></p>
    <div className="flex flex-col gap-y-4 pt-3 md:text-[18px] text-[14px] font-normal text-[#3B7A83]">
      <a href="https://ejari.sa/tnc" className="hover:underline hover:text-hover-color">{t("translation:help.aboutUs.termsAndConditions")}</a>
      <a href=" https://ejari.sa/pp" className="hover:underline hover:text-hover-color">{t("translation:help.aboutUs.privacyPolicy")}</a>
      <a href=" https://ejari.sa/sc" className="hover:underline hover:text-hover-color">{t("translation:help.aboutUs.shariahCompliance")}</a>
    </div>
  </div>
  )
}

export default AboutUs
