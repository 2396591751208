export const questions = [
  {
    id: 1,
    question: "What is Ejari and how does it work?",
    question_ar: "ما هي إيجاري وكيف يعمل؟",
    answer:
      "Ejari is a Saudi real estate technology company that provides a rent now and pay later service for residential units. Ejari rents the unit on behalf of the customer for a period of one year or six months and then re-rents it to the customer with a monthly payment plan. In exchange for this service, Ejari charges a sublease fee.",
    answer_ar:
      " ايجاري هي شركة سعودية للتقنيات العقارية تقدم خدمة استأجر الآن وإدفع لاحقًا للوحدات السكنية. تقوم إيجاري بتأجير الوحدة بالنيابة عن العميل لمدة سنة أو ستة أشهر ثم تعيد تأجيرها للعميل بخطة سداد شهرية. مقابل هذه الخدمة، تفرض إيجاري رسوم تأجير من الباطن .",
  },
  {
    id: 2,
    question: "What is the calculated profit rate?",
    question_ar: " ما هو معدل الربح المحتسب؟ ",
    answer:
      "The Ejari profit rate ranges depending on various elements, including the applicant's Credit Score and monthly credit obligations",
    answer_ar:
      " يتراوح معدل ربح ايجاري اعتمادًا على عناصر مختلفة، بما في ذلك نتيجة سمة لمقدم الطلب والتزاماته الائتمانية الشهرية .",
  },
  {
    id: 3,
    question: "How can I get a lease agreement between the landlord and Ejari?",
    question_ar: "كيف يمكنني الحصول على عقد إيجار بين المالك وإيجاري؟",
    answer:
      "Ask the landlord to register the contract with Ejari's name and commercial registration number, but make sure it allows the sub-leasing option, then we will be able to re-rent it to you!",
    answer_ar:
      "اطلب من الملاك تسجيل العقد باسم إيجاري ورقم سجله التجاري، ولكن تأكد من أنه يسمح بخيارات التأجير من الباطن، ثم سنكون قادرين على إعادة تأجيره لك!",
  },
];