import React from "react";
import Clock from "@/assets/images/ticket/clock.svg";
import browserLanguage from "@/hooks/browserLanguage";
import { ColorProps } from "@/types/colorIcon.type";



const ClockIcon: React.FC<ColorProps> = ({ className = "" }) => {
  const defaultClass = browserLanguage ? " " : " ";

  return (
    <div className={`${defaultClass} ${className}`}>
      <img src={Clock} />
    </div>
  );
};

export default ClockIcon;
