import React from 'react';
import CalendarIcons from "@/assets/images/icons/calendarIcon.svg" 

const CalendarIcon: React.FC = () => {
  return (
    <div className="rounded-lg">
      <img src={CalendarIcons} alt="Custom Icon" />
    </div>
  );
};

export default CalendarIcon;
