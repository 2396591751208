import About from '@/assets/images/help/Real_Estate_General_Authority_Logo.svg'
import { t } from 'i18next'

const LicenseInfo = () => (
    <div className="flex justify-center items-center mt-4 h-36  rounded-[10px]">
        <div className="flex flex-col text-center items-center justify-center  text-xs text-gray-500">
            <h1 className="md:text-[12px] text-[12px]">
                {t('translation:help.aboutUs.licenseInfo.prompt')}
            </h1>
            <img
                src={About}
                className={`w-[70%]  pt-4`}
                alt="Licensed by the Real Estate General Authority"
            />
            <h1 className="md:text-[14px] text-[12px] hover:text-[#60A77F] md:pt-8 pt-2">
                <a
                    href="https://pub-220913ebf1fa4918b319ea51d7c18555.r2.dev/Property%20Management%20License%202025.pdf"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    {t('translation:help.aboutUs.licenseInfo.licenseNumber')}
                </a>
            </h1>
        </div>
    </div>
)

export default LicenseInfo
