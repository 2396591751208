import TicketsList from '@/components/Help/Tickets/TicketsList';
import browserLanguage from '@/hooks/browserLanguage';
import { t } from 'i18next';
import React from 'react';

const TicketsPage: React.FC = () => {
  return (
    <div className="flex justify-center items-center ">
      <div className="h-full w-full md:p-10    ">
        <div className=" h-full w-full  bg-[rgb(251,251,251)]  md:p-10  p-5">
          <h1 className={`text-[32px]  font-bold mb-5  ${browserLanguage ? "md:mr-14 mr-8 ":"md:ml-14 ml-8 "}  `}>{t("ticket.submitMyTickets")}</h1>
          <div>
            <TicketsList />
          </div>
        </div>
      </div>
    </div>
  );
};

export default TicketsPage;
