import { postRequest } from '@/api/api.common'
import { apiEndpoints } from '@/api/api.endpoints'
import fileType from '@/assets/images/icons/Filetypes.svg'
import browserLanguage from '@/hooks/browserLanguage'
import { QueryObserverResult, RefetchOptions } from '@tanstack/react-query'
import { t } from 'i18next'
import { useEffect, useState } from 'react'
import { RiCloseLine, RiEyeLine } from 'react-icons/ri'
import Arrowright from '../icons/Arrowright'
import DocumentuploadIcon from '../icons/Documentupload'
import TrashIcon from '../icons/TrashIcon'
import { Button } from '../ui/button'
import { Input } from '../ui/input'
import SuccessHelpRequest from './Tickets/SuccessHelpRequest'

interface ShowModalProps {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    refetchUserData: (
        options?: RefetchOptions
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    ) => Promise<QueryObserverResult<any, Error>>
    showModal: boolean
    closeModal: () => void
}
const selected = [
    { title: 'ACCOUNT_ISSUE', message: t('ticket.accountIssue') },
    { title: 'TECHNICAL_ISSUE', message: t('ticket.technicalIssue') },
    { title: 'BILLING_ISSUE', message: t('ticket.paymentIssue') },
]
const TicketPopup = ({
    showModal,
    closeModal,
    refetchUserData,
}: ShowModalProps) => {
    const [selectedCategory, setSelectedCategory] = useState<string>(
        selected[0].title
    )
    const [isDropdownOpen, setIsDropdownOpen] = useState(false)
    const [content, setcontent] = useState('')
    const [file, setFile] = useState<File | null>(null)
    const [isFormValid, setIsFormValid] = useState(false)
    const [showModalSuccess, setShowModalSuccess] = useState(false)
    const [fileUrl, setFileUrl] = useState<string | null>(null)
    const [isLoading, setIsLoading] = useState(false)

    const handleClearFile = () => {
        setFile(null)
        setFileUrl(null)

        const fileInput = document.getElementById(
            'fileInput'
        ) as HTMLInputElement
        if (fileInput) {
            fileInput.value = ''
        }
    }

    useEffect(() => {
        if (content.trim() !== '') {
            setIsFormValid(true)
        } else {
            setIsFormValid(false)
        }
    }, [content])

    const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const selectedFile = e.target.files?.[0]
        if (selectedFile) {
            setFile(selectedFile)
            setFileUrl(URL.createObjectURL(selectedFile))
        }
    }

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault()
        setIsLoading(true)

        const formData = new FormData()
        formData.append('subject', selectedCategory)
        formData.append('category', selectedCategory)
        if (file) {
            formData.append('file', file)
        }
        formData.append('content', content)

        try {
            const response = await postRequest(apiEndpoints.tickets, formData, {
                headers: { 'Content-Type': 'multipart/form-data' },
            })
            if (response.status === 201) {
                await refetchUserData()
                setIsLoading(false)
                closeModal()
            }
        } catch (error) {
            console.error('Error submitting form:', error)
        }
    }

    return (
        <div dir={t('common.dir') || 'rtl'}>
            <SuccessHelpRequest
                showModal={showModalSuccess}
                closeModal={() => setShowModalSuccess(false)}
            />
            {showModal && (
                <div className="fixed inset-0  flex justify-center items-center bg-black bg-opacity-50 z-50">
                    <div className="relative justify-center items-center bg-white w-[90%]  h-[84%] md:h-max  md:w-[35%]    mx-auto p-6 rounded-[16px] shadow-md">
                        <RiCloseLine
                            onClick={closeModal}
                            className={`absolute top-4 bg-[#EFEFF1] rounded-full cursor-pointer text-[24px] ${
                                browserLanguage ? 'left-4' : 'right-4'
                            }`}
                        />
                        <div className=" items-center w-full md:text-[28px] text-[20px] font-semibold  ">
                            {t('ticket.title')}
                        </div>
                        <div className=" mt-2 items-center md:text-[14px] text-[12px] text-[#4E4D59] font-normal w-full]">
                            {t('ticket.description')}
                        </div>
                        <div className="flex flex-col ">
                            <form
                                onSubmit={handleSubmit}
                                className="w-full  md:p-4 mt-4 md:mt-0  rounded-md mb-4"
                            >
                                <div className="  items-center text-[16px] font-medium w-full">
                                    {t('ticket.selectIssue')}
                                </div>
                                <div className="flex flex-col   gap-4 pt-2 ">
                                    {browserLanguage ? (
                                        <Arrowright
                                            className={`absolute z-10   ${
                                                isDropdownOpen
                                                    ? ' rotate-90 md:left-[15%] left-[18%] pt-8'
                                                    : '-rotate-90  left-[10%]  pt-8'
                                            } `}
                                            strokeColor="#81808C"
                                        />
                                    ) : (
                                        <Arrowright
                                            className={`absolute z-10   ${
                                                isDropdownOpen
                                                    ? ' -rotate-90 right-[14%] pt-8'
                                                    : 'rotate-90  right-[10%]  pt-8'
                                            } `}
                                            strokeColor="#81808C"
                                        />
                                    )}
                                    <select
                                        id="duration"
                                        name="duration"
                                        required
                                        className="w-full  p-2 appearance-none  h-14 font-normal text-[18px] rounded-[8px] border border-[#EFEFF1] bg-white text-gray-700"
                                        value={selectedCategory}
                                        onFocus={() => setIsDropdownOpen(true)}
                                        onBlur={() => setIsDropdownOpen(false)}
                                        onChange={(e) => {
                                            setSelectedCategory(e.target.value)
                                            console.log(
                                                selectedCategory,
                                                e.target.value
                                            )
                                        }}
                                    >
                                        {selected.map((option) => (
                                            <option
                                                key={option.title}
                                                value={option.title}
                                            >
                                                {option.message}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                                <div className="  items-center text-[16px] font-medium  mt-4 w-full">
                                    {t('ticket.describeProblem')}
                                </div>
                                <Input
                                    onChange={(e) => setcontent(e.target.value)}
                                    placeholder={t('ticket.messagePlaceholder')}
                                    className="h-28 text-[14px] pb-16 "
                                />
                                <div className="  ">
                                    <div className="flex gap-2 pt-3 ">
                                        <p className="md:text-[16px]   pb-3  text-[16px] font-medium ">
                                            {t('ticket.attachFile')}
                                        </p>
                                        <span className="text-gray-500">
                                            {t('StartRenting.Optional')}
                                        </span>
                                    </div>

                                    {!file?.name ? (
                                        <div className="flex items-center gap-1">
                                            <label
                                                htmlFor="fileInput"
                                                className="flex items-center justify-center border border-[#60A77F] md:w-[22%] w-[40%] h-14 gap-1 text-[#60A77F] md:text-lg rounded-[12px] cursor-pointer "
                                            >
                                                {t('SIMAH.Upload')}
                                                {/* <RiUploadCloud2Line className="text-2xl" /> */}
                                                <DocumentuploadIcon />
                                            </label>
                                        </div>
                                    ) : (
                                        <div className="h-full w-full">
                                            <div className="flex justify-between border h-20 rounded-[16px] items-center">
                                                <div className="flex items-center">
                                                    {file && (
                                                        <img
                                                            src={fileType}
                                                            alt="Uploaded File Preview"
                                                            className="rounded-md md:h-12 h-14 ml-4 pr-2 md:pr-0 md:ml-0 md:w-32"
                                                        />
                                                    )}
                                                    <div>
                                                        <h1>
                                                            {file?.name ||
                                                                'No file selected'}
                                                        </h1>
                                                        <p
                                                            className={`text-[#62606F] text-[12px] ${
                                                                browserLanguage
                                                                    ? 'force-ltr'
                                                                    : ''
                                                            } `}
                                                        >
                                                            {file
                                                                ? file.size <
                                                                  1024
                                                                    ? `${file.size} bytes`
                                                                    : `${(
                                                                          file.size /
                                                                          1024
                                                                      ).toFixed(
                                                                          2
                                                                      )} KB`
                                                                : 'N/A'}
                                                        </p>
                                                    </div>
                                                </div>

                                                <div className="flex items-center gap-8">
                                                    <div>
                                                        <button
                                                            onClick={
                                                                handleClearFile
                                                            }
                                                            className="flex items-center justify-center font-medium md:text-lg rounded-[12px] cursor-pointer mt-2"
                                                        >
                                                            <TrashIcon />
                                                        </button>
                                                    </div>

                                                    {fileUrl && (
                                                        <a
                                                            href={fileUrl}
                                                            className="flex items-center w-10 gap-1 text-textgray md:text-lg rounded-lg cursor-pointer"
                                                            target="_blank"
                                                            rel="noopener noreferrer"
                                                        >
                                                            <RiEyeLine className="ml-1 text-2xl" />
                                                        </a>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    )}

                                    <Input
                                        type="file"
                                        id="fileInput"
                                        className="hidden "
                                        onChange={handleFileChange}
                                    />
                                </div>
                                <div className="w-full pt-4 rounded-md ">
                                    <Button
                                        isLoading={isLoading}
                                        type="submit"
                                        onClick={() =>
                                            setShowModalSuccess(true)
                                        }
                                        disabled={!isFormValid}
                                        className={`w-full h-14 text-[18px] font-semibold rounded-[12px] flex justify-center items-center ${
                                            isFormValid
                                                ? 'bg-[#60A77F] text-white'
                                                : 'bg-[#E0DFE2] text-[#81808C]'
                                        }`}
                                    >
                                        {t('ticket.submitButton')}
                                    </Button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            )}
        </div>
    )
}

export default TicketPopup
