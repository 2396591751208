import PendingIcon from '@/components/icons/PendingIcon'
import { Button } from '@/components/ui/button'
import { mapTicketCategory } from '@/components/utils/ticketCategory'
import browserLanguage from '@/hooks/browserLanguage'
import { TicketProps } from '@/types/ticketProps.type'
import { t } from 'i18next'
import React from 'react'
import { useNavigate } from 'react-router-dom'


const formatDate = (dateString?: string) => {
    if (!dateString) {
        return <div className="bg-[#EFEFF1] w-28 min-h-[20px]"></div>
    }
    const date = new Date(dateString)
    if (isNaN(date.getTime())) {
        return <div className="bg-[#EFEFF1] w-28 min-h-[20px]"></div>
    }

    const options: Intl.DateTimeFormatOptions = {
        year: 'numeric',
        month: 'short',
        day: 'numeric',
    }

    return new Intl.DateTimeFormat(
        browserLanguage ? 'ar-en' : 'en-en',
        options
    ).format(date)
}

const Ticket: React.FC<TicketProps> = ({ ticket }) => {
    const navigate = useNavigate()

    const formattedDate = formatDate(ticket.createdAt)

    const handleViewClick = () => {
        navigate(`/tickets/${ticket.id}`)
    }

    return (
        <thead className="md:block   h-full w-full ">
            <tr className="md:border-b grid grid-cols-2 md:h-16 p-4   gap-x-9 md:gap-x-0    bg-white rounded-[16px] md:rounded-[0px] border border-[#F7F7F8] shadow-sm mt-3 md:mt-0 md:grid-cols-5 md:w-full">
                <th className="rounded-tl text-[16px] font-normal  t p-2">
                    #{ticket.id}
                </th>
                <th className="p-2 text-[16px] font-normal">{formattedDate}</th>
                <th className="p-2 text-[16px] font-normal ">
                    {mapTicketCategory(ticket?.properties?.hs_ticket_category)}
                </th>
                <th
                    className={`flex   p-3  md:p-1 w-28  md:h-8 h-7 mt-2 md:mt-0  gap-1  items-center justify-center  text-[13px] font-normal  rounded-full bg-[#FEF8D8] text-[#978325]`}
                >
                    {' '}
                    {t('ticket.inProgress')} <PendingIcon />
                </th>
                <th>
                    <Button
                        variant={'default'}
                        onClick={handleViewClick}
                        className="text-center    rounded-[12px] text-[14px] font-medium text-popover bg-[#60A77F]    md:mr-2 md:w-32 w-[100%] "
                    >
                        {t('ticket.view')}
                    </Button>
                </th>
            </tr>
        </thead>
    )
}

export default Ticket
