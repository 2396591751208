export const apiEndpoints = {
    userData: "/users/me",
    partners: "/partners-network",
    notifications: "/notifications",
    rentalRequests: "/rentals/requests",
    tickets: "/external/hs-tickets/",
    checkUserExists: "/auth/check-user-exists/",
    authLogin: "/auth/login/",
    authRefresh: "/auth/refresh",
    sendOtp: "/auth/otp/send"

}