import React from "react";
import Success from "@/assets/images/icons/Sueccess.png";
import browserLanguage from "@/hooks/browserLanguage";
import { ColorProps } from "@/types/colorIcon.type";



const SuccessIcon: React.FC<ColorProps> = ({ className = "" }) => {
  const defaultClass = browserLanguage ? " " : " ";

  return (
    <div className={`${defaultClass} ${className}`}>
      <img src={Success} />
    </div>
  );
};

export default SuccessIcon;
