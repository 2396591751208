import { useTicketsQuery } from '@/queries/user.ticket'
import CalendarIcon from '../icons/CalendarIcon'
import { Button } from '../ui/button'
import { t } from 'i18next'
import { useNavigate } from 'react-router-dom'
import { mapTicketCategory } from '../utils/ticketCategory'


const TechnicalRequest = () => {
    const navigate = useNavigate()
    const { data: Tickets } = useTicketsQuery()

    const TicketsID =
        Tickets?.length > 0
            ? Tickets.reduce(
                  (max: number, ticket: { id: number }) =>
                      ticket.id > max ? ticket.id : max,
                  Tickets[0].id
              )
            : null

    const Ticketstitle =
        Tickets?.length > 0
            ? Tickets.reduce(
                  (max: number, ticket: { id: number }) =>
                      ticket.id > max ? ticket.id : max,
                  Tickets[0]
              )
            : null

    const lastTicketDate =
        Tickets?.length > 0
            ? new Date(Tickets[0].createdAt).toISOString()
            : 'No Date Available'
    const date = lastTicketDate ? new Date(lastTicketDate) : new Date()
    const formattedDate = date.toLocaleString('en-US', {
        year: 'numeric',
        month: 'short',
        day: '2-digit',
        hour: 'numeric',
        minute: '2-digit',
        hour12: true,
    })

    return (
        <div>
            <div className="bg-gradient-to-r border-2 mb-4  border-[#F1F7F4] rounded-[12px] from-[#FFFFFF] to-[#F1F7F4] md:h-32 md:p-0  flex justify-center">
                <div className="p-8 space-y-4 md:space-y-0 md:flex justify-between items-center w-full ">
                    <div>
                        <div className="md:flex gap-4 items-center">
                            <h1 className="text-[#141316] text-[22px] font-bold">
                                {mapTicketCategory(
                                    Ticketstitle.properties.hs_ticket_category
                                )}
                            </h1>
                            <div className="flex items-center gap-2">
                                <CalendarIcon />
                                <p className="text-[15px] text-[#4E4D59]">
                                    {formattedDate}
                                </p>
                            </div>
                        </div>
                        <h1 className="text-[#141316] text-[16px]">
                            {t('ticket.ticketNumber')} : # {TicketsID}
                        </h1>

                        <h2 className="flex justify-center items-center mt-2 text-[14px] text-[#978325] rounded-[16px] bg-[#FEF8D8] w-28 h-8">
                            {' '}
                            {t('dashboard.submittedRequest.underReview')}
                        </h2>
                    </div>
                    <div className="flex ">
                        <Button
                        variant={'default'}
                            onClick={() => navigate('/tickets')}
                            className="  text-[16px] font-medium md:mr-2 md:w-32 w-[100%] h-12"
                        >
                            {t('ticket.submitMyTickets')}
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default TechnicalRequest
