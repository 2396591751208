import React from "react";
import CategoryIcon from "@/assets/images/ticket/category.svg";
import browserLanguage from "@/hooks/browserLanguage";
import { ColorProps } from "@/types/colorIcon.type";



const Category: React.FC<ColorProps> = ({ className = "" }) => {
  const defaultClass = browserLanguage ? " " : " ";

  return (
    <div className={`${defaultClass} ${className}`}>
      <img src={CategoryIcon} />
    </div>
  );
};

export default Category;
