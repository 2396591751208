import i18n from "@/i18n";
import useAuthStore from "@/store/auth";
import axios, { AxiosRequestConfig } from "axios";

const api = axios.create({
  baseURL: import.meta.env.VITE_API_URL,
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json",
  },
});

api.interceptors.request.use((config) => {
  const token = localStorage.getItem("accessToken");
  const configReq = config;
  if (configReq.headers) {
    configReq.headers.Authorization = token ? `Bearer ${token}` : "";
    configReq.headers['Accept-Language'] = i18n.language;
  }
  return configReq;
});

api.defaults.timeout = 100000;

api.interceptors.response.use(
  function success(response) {
    return response;
  },
  async function fail(error) {
    const originalRequest = error.config;

    if (error.response && error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;

      try {
        const updateToken = useAuthStore.getState().updateToken;
        await updateToken();

        const newAccessToken = useAuthStore.getState().accessToken;

        originalRequest.headers["Authorization"] = `Bearer ${newAccessToken}`;

        return api(originalRequest);
      } catch (updateError) {
        localStorage.clear();
        if (!window.location.href.includes("signIn")) {
          window.location.href = "/auth/signin";
        }
        return Promise.reject(updateError);
      }
    }

    return Promise.reject(error);
  }
);

export const getRequest = async (URL: string) => {
  try {
    const response = await api.get(URL);
    return response;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    if (error.response && error.response.status === 400 && error.response.data.message === "INVALID_USER") {
      localStorage.clear();
      if (!window.location.href.includes("signIn")) {
        window.location.href = "/auth/signIn";
      }
      return Promise.reject({ success: false, message: "INVALID_USER" });
    }

    return Promise.reject(error);
  }
};


export const deleteRequest = async (URL: string, id: number | string) => {
  try {
    const response = await api.delete(`${URL}`.concat(id.toString().concat("/")));
    return response;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const postRequest = async <T>(
  URL: string,
  data: T,
  config?: AxiosRequestConfig<T> | undefined
) => {
  try {
    const response = await api.post(`${URL}`, data, config);
    return response;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const putRequest = async <T>(
  URL: string,
  data: T,
  config?: AxiosRequestConfig
) => {
  try {
    const response = await api.put(URL, data, config);
    return response;
  } catch (error) {
    return Promise.reject(error);
  }
};
