import React from "react";
import Ticket from "./Ticket";
import { useTicketsQuery } from "@/queries/user.ticket";
import Spinner from "@/assets/images/animation/Spinner_Ejari.json";
import Lottie from "lottie-react";
import { t } from "i18next";

const TicketsList: React.FC = () => {
  const { data: tickets, isLoading, error } = useTicketsQuery();

  if (isLoading) {
    return <div>  {isLoading && (
      <div className="flex justify-center items-center w-full">
        {/* Lottie Spinner */}
        <Lottie animationData={Spinner} height={120} width={120} />
      </div>
     )}</div>;
  }

  if (error) {
    return <div>Error loading tickets</div>;
  }

  return (
    <div className="container w-full    ">
      <table className="table-auto w-full">
        <thead className="hidden md:block">
          <tr className="bg-[#F7F7F8]  h-16 p-4 font-medium md:text-[14px] rounded-t-[16px] grid md:grid-cols-5 w-full">
            <th className="rounded-tl p-2">{t("ticket.ticketNumber")}</th>
            <th className="p-2">{t("ticket.dateSubmitted")}</th>
            <th className="p-2 ">{t("ticket.category")}</th>
            <th className="p-2 ">{t("ticket.status")}</th>
            <th className="p-2 ">{t("ticket.actions")}</th>
          </tr>
        </thead>
        <tbody className="md:bg-white   rounded-b-3xl">
          {tickets && tickets.length > 0 ? (
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            tickets.map((ticket: any, index: number) => (
              <Ticket key={index} ticket={ticket} />
            ))
          ) : (
            <tr>
              <td colSpan={6} className="text-center py-4">
                No tickets available.
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};

export default TicketsList;
