import React from 'react'
import { ReactSVG } from 'react-svg'
import Trash from '@/assets/images/icons/trash.svg'
import browserLanguage from '@/hooks/browserLanguage'
import { ColorProps } from "@/types/colorIcon.type";



const TrashIcon: React.FC<ColorProps> = ({
    className = 'mb-2',
    strokeColor = '',
}) => {
    const defaultClass = browserLanguage ? ' ' : ' '

    return (
        <div className={`${defaultClass} ${className}`}>
            <ReactSVG
                src={Trash}
                beforeInjection={(svg) => {
                    svg.querySelector('path')?.setAttribute(
                        'stroke',
                        strokeColor
                    )
                }}
            />
        </div>
    )
}

export default TrashIcon
