import { getRequest } from "@/api/api.common";
import { apiEndpoints } from "@/api/api.endpoints";
import { useQuery } from "@tanstack/react-query";




export const fetchTickets = async () => {
    const response = await getRequest(apiEndpoints.tickets);
    return response.data;
};

export const useTicketsQuery = () => useQuery({ queryKey: ['hs-tickets'], queryFn: fetchTickets })

