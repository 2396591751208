import { lazy } from "react";
export const Layout = lazy(() => import("./components/Layouts/Layout.tsx"));
export const Protected = lazy(() => import("./components/Protected.tsx"));
export const Unauthorized = lazy(() => import("./components/Unauthorized.tsx"));
export const Error = lazy(() => import("./error.tsx"));
export const SignIn = lazy(() => import("./pages/auth/signIn.tsx"));
export const Onboarding = lazy(() => import("./pages/auth/onboarding.tsx"));
export const App = lazy(() => import("./pages/dashboard/index.tsx"));
export const MyRentings = lazy(() => import("./pages/MyRentals/myRentals.tsx"));
export const PartnerNetwork = lazy(() => import("./pages/dashboard/partnernetwork.tsx"));
export const Profile = lazy(() => import("./pages/Profile/Profile.tsx"));
