import browserLanguage from '@/hooks/browserLanguage'
// import { t } from "i18next";
import { Button } from '../ui/button'
import WhatsappIcon from '../icons/WhatsappIcon'
import { useState } from 'react'
import TicketPopup from './TicketPopup'
import { t } from 'i18next'
import { useTicketsQuery } from '@/queries/user.ticket'

const ContactInfo = () => {
    const { data: Tickets, refetch: refetchUserData } = useTicketsQuery()

    const [showModalDeactivate, setShowModalDeactivate] = useState(false)
    return (
        <div className=" md:flex items-center justify-between md:p-8 p-4  rounded-[12px] mb-4 md:w-full  md:h-32  h-44 bg-[#EFF6F2]  ">
            <div className=" p-2 md:mb-0 mb-2">
                <h1 className="md:text-[28px]  text-[24px] font-semibold">
                    {' '}
                    {t('help.title')}
                </h1>

                <div className=" gap-2 flex  mt-2 md:mt-0 ">
                    <p className="md:text-[17px]   text-[13px] font-medium text-card">
                        {t('help.feedback')}
                    </p>
                    <a
                        href="tel:+966115107033"
                        target="_blank"
                        className={`md:text-[17px] text-[14px] font-semibold hover:underline ${
                            browserLanguage ? 'force-ltr' : 'flex'
                        }`}
                    >
                        +966115107033
                    </a>
                </div>
            </div>
            <TicketPopup
                showModal={showModalDeactivate}
                closeModal={() => setShowModalDeactivate(false)}
                refetchUserData={refetchUserData}
            />
            <div className="flex gap-4 items-center  md:pt-0  ">
                {Tickets && Tickets.length !== 1 && (
                    <Button
                        variant={'outline'}
                        onClick={() => setShowModalDeactivate(true)}
                        className="w-40 md:text-[18px] text-[14px]  font-semibold  h-12"
                    >
                        {t('help.contact.submitTicket')}
                    </Button>
                )}

                <a href="https://wa.me/966115107033">
                    <Button
                        variant={'default'}
                        className="w-40 h-12 gap-2 font-semibold text-[18px] "
                    >
                        <WhatsappIcon />
                        {t('help.contact.contact')}
                    </Button>
                </a>
            </div>
        </div>
    )
}

export default ContactInfo
