import { t } from "i18next";

export const mapTicketCategory = (category: string) => {
    switch (category) {
        case "ACCOUNT_ISSUE":
            return `${t("ticket.account")}`;
        case "TECHNICAL_ISSUE":
            return `${t("ticket.technical")}`;
        case "BILLING_ISSUE":
            return `${t("ticket.payment")}`;
        default:
            return category;
    }
};

