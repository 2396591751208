import React from "react";
import CalendarNextIcon from "@/assets/images/icons/calendar.svg";
import browserLanguage from "@/hooks/browserLanguage";
import { ColorProps } from "@/types/colorIcon.type";



const Calendar: React.FC<ColorProps> = ({ className = "" }) => {
  const defaultClass = browserLanguage ? " " : " ";

  return (
    <div className={`${defaultClass} ${className}`}>
      <img src={CalendarNextIcon} />
    </div>
  );
};

export default Calendar;
