import React from "react";
import MessageText from "@/assets/images/ticket/message-text.svg";
import browserLanguage from "@/hooks/browserLanguage";
import { ColorProps } from "@/types/colorIcon.type";



const MessageTextIcon: React.FC<ColorProps> = ({ className = "" }) => {
  const defaultClass = browserLanguage ? " " : " ";

  return (
    <div className={`${defaultClass} ${className}`}>
      <img src={MessageText} />
    </div>
  );
};

export default MessageTextIcon;
