import * as React from 'react'
import { cn } from '@/lib/shadcn'
import browserLanguage from '@/hooks/browserLanguage'
import QuestionCircleIcon from '../icons/QuestionCircleIcon'

export interface InputProps
    extends React.InputHTMLAttributes<HTMLInputElement> {
    labelname?: React.ReactNode
    labelClassName?: string
    error?: boolean
    errorMessage?: string
    info?: string
    showCurrency?: boolean
}

const Input = React.forwardRef<HTMLInputElement, InputProps>(
    (
        {
            className,
            type,
            labelClassName,
            error,
            errorMessage,
            info,
            showCurrency,
            ...props
        },
        ref
    ) => {
        return (
            <div>
                <label
                    className={cn(
                        `block text-[#141316] text-[16px] font-medium mb-1`,
                        labelClassName
                    )}
                    htmlFor={props.id}
                >
                    {props.labelname}
                </label>
                <div className="relative flex items-center">
                    <input
                        type={type}
                        className={cn(
                            `mt-1 block w-full h-14 border text-[#141316]  border-[#EFEFF1] bg-white rounded-[8px] ${
                                browserLanguage ? 'pr-3 force-rtl' : 'pl-3'
                            }`,
                            error ? 'border-red-500' : 'border-[#EFEFF1]',
                            className
                        )}
                        ref={ref}
                        {...props}
                    />
                    {showCurrency && (
                        <span
                            className={`absolute  mt-1 text-gray-500 text-sm ${
                                browserLanguage ? 'left-3' : 'right-3'
                            }`}
                        >
                            SAR
                        </span>
                    )}
                </div>
                {error && errorMessage && (
                    <p className="text-red-500 text-sm mt-2">{errorMessage}</p>
                )}
                {info && (
                    <div
                        className={`flex gap-2 items-center text-[12px]  font-normal pt-1 text-gray-600 ${
                            browserLanguage ? 'pr-3' : 'pl-3'
                        }`}
                    >
                        <QuestionCircleIcon />
                        <h1>{info}</h1>
                    </div>
                )}
            </div>
        )
    }
)

Input.displayName = 'Input'

export { Input }
