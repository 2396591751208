import React, { useEffect, Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { I18nextProvider, useTranslation } from 'react-i18next';
import { RouterProvider } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import { PostHogProvider } from 'posthog-js/react';
import posthog from 'posthog-js';
import i18n from './i18n';
import './styles/globals.css';
import './i18n';
import router from './routes';

posthog.init(import.meta.env.VITE_POSTHOG_TOKEN, {
    api_host: 'https://us.i.posthog.com',
    person_profiles: 'identified_only',
});
posthog.capture('my event', { property: 'value' });
posthog.capture('$pageview');

function Main() {
    const { i18n } = useTranslation();
    useEffect(() => {
        const textDirectionClass = i18n.language.startsWith('ar') ? 'text-rtl' : 'text-ltr';
        document.body.className = textDirectionClass;
    }, [i18n.language]);

    return (
        <Suspense>
            <RouterProvider router={router} />
        </Suspense>
    );
}

const queryClient = new QueryClient();
const helmetContext = {};
const rootMap = new WeakMap();

const container = document.getElementById('root');
if (container) {
    let root;
    if (!rootMap.has(container)) {
        root = ReactDOM.createRoot(container);
        rootMap.set(container, root);
    } else {
        root = rootMap.get(container);
    }

    root.render(
        <React.StrictMode>
            <PostHogProvider client={posthog}>
                <HelmetProvider context={helmetContext}>
                    <I18nextProvider i18n={i18n}>
                        <QueryClientProvider client={queryClient}>
                            <Main />
                        </QueryClientProvider>
                    </I18nextProvider>
                </HelmetProvider>
            </PostHogProvider>
        </React.StrictMode>
    );
}
