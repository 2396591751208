import Calendar from '@/components/icons/Calendar'
import CategoryIcon from '@/components/icons/CategoryIcon'
import ClockIcon from '@/components/icons/ClockIcone'
import MessageTextIcon from '@/components/icons/MessageTextIcon'
import { mapTicketCategory } from '@/components/utils/ticketCategory'
import browserLanguage from '@/hooks/browserLanguage'
import { useTicketsQuery } from '@/queries/user.ticket'
import { t } from 'i18next'
import Spinner from '@/assets/images/animation/Spinner_Ejari.json'
import Lottie from 'lottie-react'
import React from 'react'
import { useParams } from 'react-router-dom'

const formatDate = (dateString?: string) => {
    if (!dateString) {
        return <div className="bg-[#EFEFF1] w-28 min-h-[20px]"></div>
    }
    const date = new Date(dateString)
    if (isNaN(date.getTime())) {
        return <div className="bg-[#EFEFF1] w-28 min-h-[20px]"></div>
    }

    const options: Intl.DateTimeFormatOptions = {
        year: 'numeric',
        month: 'short',
        day: 'numeric',
    }

    return new Intl.DateTimeFormat(
        browserLanguage ? 'ar-en' : 'en-en',
        options
    ).format(date)
}

const TicketDetails: React.FC = () => {
    const { ticketNumber } = useParams<{ ticketNumber: string }>()
    const { data: Tickets, isLoading, error } = useTicketsQuery()

    if (isLoading) {
        return (
            <div>
                {' '}
                {isLoading && (
                    <div className="flex justify-center items-center w-full">
                        {/* Lottie Spinner */}
                        <Lottie
                            animationData={Spinner}
                            height={120}
                            width={120}
                        />
                    </div>
                )}
            </div>
        )
    }

    if (error) {
        return <div>Error loading tickets</div>
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const ticket = Tickets?.find((ticket: any) => ticket.id === ticketNumber)

    if (!ticket) {
        return <div>Ticket not found</div>
    }

    const formattedDate = formatDate(ticket.createdAt)

    return (
        <div className="md:p-8 p-4 md:ml-10 md:mr-10 ml-5 mr-5 mt-10 h-full bg-[#FBFBFB] rounded-lg">
            <div>
                <div className="flex   items-center gap-2">
                    <h1 className={`text-[32px]  text-[#141316] font-bold`}>
                        {browserLanguage
                            ? `${t('ticket.ticket')} ${ticket.id}#`
                            : `${t('ticket.ticket')} #${ticket.id}`}
                    </h1>
                    <span
                        className={`md:flex hidden   gap-1 w-32 items-center justify-center  text-[13px] font-normal py-1 rounded-full bg-[#FEF8D8] text-[#978325]`}
                    >
                        {/* {ticket.status} */}
                        {t('ticket.inProgress')}
                    </span>
                </div>
                <span
                    className={`flex md:hidden mb-4 gap-1 w-32 items-center justify-center  text-[13px] font-normal py-1 rounded-full bg-[#FEF8D8] text-[#978325]`}
                >
                    {/* {ticket.status} */}
                    {t('ticket.inProgress')}
                </span>
            </div>
            <div className=" space-y-2 bg-white border border-[#EFEFF1]  rounded-[16px] p-4">
                <p className="flex font-semibold text-[24px] ">
                    <h1>{t('ticket.ticketinfo')}</h1>
                </p>
                <p className="flex gap-1 items-center font-normal text-[16px] ">
                    <MessageTextIcon />
                    <h1>{t('ticket.ticketNumber')}:</h1> #{ticket.id}
                </p>
                <p className="flex gap-1 items-center font-normal text-[16px] ">
                    <ClockIcon />
                    <h1>{t('ticket.status')}:</h1> {t('ticket.inProgress')}
                </p>
                <p className="flex gap-1 items-center font-normal text-[16px] ">
                    <CategoryIcon />
                    <h1>{t('ticket.category')}:</h1>{' '}
                    {mapTicketCategory(ticket.properties?.hs_ticket_category) ||
                        'N/A'}
                </p>
                <p className="flex gap-1 items-center font-normal text-[16px] ">
                    <Calendar className="w-[16px]" />
                    <h1>{t('ticket.dateSubmitted')}:</h1> {formattedDate}
                </p>
            </div>
            <div className="bg-white border border-[#EFEFF1] rounded-[16px] mt-8  p-4">
                <h2 className="flex font-semibold text-[24px] ">
                    {t('ticket.issueDescription')}
                </h2>
                <p className="flex font-normal text-[16px] ">
                    {ticket.properties?.content || 'No description available.'}
                </p>
            </div>
            <div className="bg-white border border-[#EFEFF1] rounded-[16px] p-4 mt-8">
                <h2 className="flex font-semibold text-[24px] ">
                    {t('ticket.ticketActivity')}
                </h2>
                <div className="mt-2">
                    <div className="border-b mt-3 pb-3">
                        <h1 className="flex gap-1 items-center  font-normal md:text-[16px] text-[10px] mb-2 md:mb-0 ">
                            <Calendar className="w-[16px]" />
                            {formattedDate}: {t('ticket.userTicket')}
                        </h1>
                        <h2 className="flex font-normal text-[16px] ">
                            {ticket.properties?.content ||
                                'No description available.'}
                        </h2>
                    </div>
                    <div className="mt-4">
                        <h1 className="flex gap-1 items-center font-normal md:text-[16px] text-[10px] mb-2 md:mb-0 ">
                            <Calendar className="w-[16px]" />
                            {formattedDate}: {t("ticket.adminSupport")}
                        </h1>
                        <h2 className="flex font-normal md:text-[16px] text-[12px]">
                        {t("ticket.adminMessage")}
                        </h2>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default TicketDetails
